<script>
  import CurvedHeader from '@/components/general/curved-header/CurvedHeader.vue';
  import { mapGetters } from 'vuex';
  import getThumbnail from '@/utils/getThumbnail.js';

  export default {
    name: 'author-details-component',
    components: {
      CurvedHeader,
    },
    props: {
      author: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        isLoading: false,
        avatarSize: 160,
        thumbnailUrl: null,
      };
    },
    computed: {
      ...mapGetters('CoursesModule', ['getCoursesByAuthor']),
      courses() {
        return this.getCoursesByAuthor(this.author.uid);
      },
    },
    async mounted() {
      await this.setThumbnailUrl();
      this.resizeAvatar();
      window.addEventListener('resize', this.resizeAvatar);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.resizeAvatar);
    },
    methods: {
      async setThumbnailUrl() {
        try {
          this.thumbnailUrl = await getThumbnail({
            url: this.author.photoURL,
            folder: 'AuthorsPhotos',
          });
        } catch {
          // fallback to the original image
          this.thumbnailUrl = this.author.photoURL;
        }
      },
      resizeAvatar() {
        if (window.innerHeight < 610) {
          this.avatarSize = 100;
        } else if (window.innerHeight < 730) {
          this.avatarSize = 130;
        } else {
          this.avatarSize = 160;
        }
      },
    },
  };
</script>
