<template>
  <v-card tile>
    <!-- header -->
    <div>
      <CurvedHeader title="" />
      <div style="position: absolute; top: 10px; left: 10px">
        <v-btn
          icon
          @click="$router.back()">
          <v-icon class="mdi-arrow-left-author">mdi-arrow-left</v-icon>
        </v-btn>
      </div>
      <div style="position: absolute; left: 50%; transform: translateX(-50%); top: 10vh">
        <v-avatar
          :size="avatarSize"
          class="mr-4"
          style="border: 4px solid #ffffff">
          <v-img :src="thumbnailUrl" />
        </v-avatar>
      </div>
    </div>

    <v-card-text class="px-6">
      <!-- author details -->
      <div class="author-details-container">
        <div class="author-details-name">{{ author.displayName }}</div>
        <div class="text-center mt-4 grey lighten-5 pa-6 rounded-lg">
          <v-tiptap
            view
            style="width: 90%; margin: 0 auto; text-align: justify"
            v-model="author.description">
          </v-tiptap>
        </div>
      </div>
      <!--author courses -->
      <div class="mt-12">
        <div class="author-courses-title">More from {{ author.displayName }}</div>
        <div class="author-courses-list mt-3">
          <v-row no-gutters>
            <v-col
              cols="6"
              class="pa-1"
              v-for="course in courses"
              :key="course.id">
              {{ course.name }}
              <CourseGridCard :course="course" />
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import AuthorDetailsComponentMixin from '@/components/authors/author-details-component/AuthorDetailsComponent.mixin.vue';
  export default {
    mixins: [AuthorDetailsComponentMixin],
  };
</script>

<style lang="css" scoped>
  .author-details-container {
    margin-top: 7vh;
  }
  .author-details-name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: var(--v-offBlack-base);
  }
  .author-details-description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: var(--v-brandGrey1-base);
  }
  .author-courses-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: var(--v-brandGrey2-base);
  }
</style>
